.reservations-container {
    width: 100%;
    height: fit-content;
    /* min-height: 1650px; */
    margin-top: 65px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 230px;
    background-color: rgb(243, 243, 243);
}

.reservations-content {
    height: 90%;
    width: 90%;
    min-width: 800px;
}

.util-field-reservations {
    width: 100%;
    height: 80px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.reservations-title {
    height: 10%;
    width: 100%;
    min-height: 50px;
    color: rgb(104, 109, 107);
    font-size: 32px;
    border: 1px solid blue;
}

.bottom-reservations-container {
    width: 100%;
    min-height: 500px;
}

.reservation-calendar {
    height: 800px;
    min-height: 800px;
    margin-top: 20px;
}

