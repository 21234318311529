.clients-container {
    width: 100%;
    height: calc(100vh - 30px);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 230px;
    background-color: rgb(243, 243, 243);
}

.clients-content {
    height: 90%;
    width: 90%;
    min-width: 800px;
}

.util-field-clients {
    width: 100%;
    height: 80px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.clients-title {
    height: 10%;
    width: 100%;
    min-height: 50px;
    color: rgb(104, 109, 107);
    font-size: 32px;
    border: 1px solid blue;
}

.bottom-clients-container {
    width: 100%;
    min-height: 500px;
}


