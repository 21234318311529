.dashboard-container {
    width: 100vw;
    height: calc(100vh - 65px);
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 230px;
    background-color: rgb(243, 243, 243);
}


.dashboard-content {
    height: 100%;
    width: 95%;
}

.upper-dash-container {
    width: 100%;
    min-width: 1100px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
}

.middle-dash-container {
    width: 100%;
    height: 45%;
    min-height: 350px;
    min-width: 1165px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bottom-left-container {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.coworks-dash,
.parceiros-dash,
.users-dash,
.store-dash,
.client-dash {
    height: 50%;
    min-width: 225px;
    min-height: 150px;
}

.dash-paper {
    padding: 15px;
}

.dash-paper-reserv {
    padding: 15px;
}

.reservas-dash {
    width: 70%;
    height: fit-content;
    min-width: 720px;
    display: flex;
    align-items: center; 
    z-index: 999;
}

.reservas-graph {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
}

.graph-reservas-paper {
    height: fit-content;
    padding: 15px;
    padding-left: 35px;
    margin-left: -10%;
   
}

.graph-items-paper {
    height: 90%;
    width: 90%;
}

.footer-dash-container {
    margin-top: 25px;
    width: 100%;
    /* height: 24vh; */
    min-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-graph-reservas-paper {
    width: 94%;
    margin-left: 17px;
}