.rbc-allday-cell {
    display: none;
}

.rbc-time-view .rbc-header {
    border-bottom: none;
}

.rbc-toolbar-label {
    text-transform: capitalize;
}

.custom-event:hover {
    background-color: #202b6a !important;
    z-index: 50;
}