.round-userCard {
    width: 66%;
    /* max-width: 250px; */
    height: 175px;
    margin-top:15px;
    overflow:hidden;
    position:relative;
    border-radius:50%;
}

.profilePic-userCard {
    position:absolute;
    top:50%;
    left:50%;
    min-width:100%;
    height:100%;
    transform:translate(-50%,-50%);
    transition: 1s ease;
}

.profilePic-userCard:hover {
    height: 110%;
    width: 110%;
}