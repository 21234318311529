.passrecovery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.passrecovery-right-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.passrecovery-inside-right-container {
    width: 50%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.passrecovery-left-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('../../assets/login/hexa_background_resized.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.passrecovery-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.passrecovery-buttondiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.passrecovery-submit-button {
    margin-top: 35px !important;
    width: 100%;
    background: linear-gradient(to right, #181048, #1728c6);
    transition: all 0.3s ease-out !important;
    box-shadow: 2px 2px 0px #181048 !important;
}

.passrecovery-submit-button:hover {
    width: 105%;
    margin-left: -10px;
}

.passrecovery-language-changer {
    padding-top: 30px;
    width: 250px;
    display: flex;
    justify-content: space-around;
}

.passrecovery-btn-focused {
    display: flex;
    align-items: center;
    color: black;
    font-weight: 600;
    border-bottom: 1px solid black;
    height: 30px;
    margin-right: 20px;
    font-size: 14px;
}

.passrecovery-btn-unfocused {
    display: flex;
    align-items: center;
    height: 30px;
    color:#cccccc;
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}

.passrecovery-btn-unfocused:hover {
    color: black;
    font-weight: 600;
}

.passrecovery-title {
    font-weight: 600;
    font-size: 32px;
    padding-bottom: 5px;
}

.passrecovery-subtitle {
    color: #808080;
    text-align: center;
}

.passrecovery-snackbar {
    background-color: rgb(207, 33, 33);
}

@media screen and (max-width: 1000px) {

    .passrecovery-left-container {
        width: 100%;
        height: 150px;
        margin-bottom: 25px;
    }

    .passrecovery-right-container {
        width: 100%;
    }

    .passrecovery-inside-right-container {
        width: 65%;

    }

    .passrecovery-left-container > p {
        color:white !important
    }
}