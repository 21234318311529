.helpMainDiv {
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 30px);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 230px;
    background-color: rgb(243, 243, 243);
}

.help-content {
    margin-top: 10px;
    height: 90%;
    width: 90%;
    min-width: 800px;
    display: flex;
    justify-content: center;
}