.analytics-container {
    width: calc(100vw - 230px);
    height: 165vh;
    margin-top: 60px;
    margin-left: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(243, 243, 243)
}

.iframe-paper {
    width: 100%;
    height: inherit;
    min-width: 1000px;
}

.loading-div {
    width: 100%;
    min-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}