.profile-container {
    width: 100vw;
    min-width: 900px;
    margin: 100px 30px 30px 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.left-container {
    height: 100%;
    width: 400px;
    margin-right: 20px;
}

.right-container {
    height: 100%;
    width: 50%;
    min-width: 600px;
}

.round {
    width:100%;
    max-width: 250px;
    height: 250px;
    margin-top:15px;
    overflow:hidden;
    position:relative;
    border-radius:50%;
}

.profilePic {
    position:absolute;
    top:50%;
    left:50%;
    min-width:100%;
    height:100%;
    transform:translate(-50%,-50%);
    transition: 2s ease;
}

.profilePic:hover {
    height: 110%;
    width: 110%;
}