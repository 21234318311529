.cowork-container {
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 30px);
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 230px;
    background-color: rgb(243, 243, 243);
}

.cowork-content {
    margin-top: 50px;
    height: 90%;
    width: 90%;
    min-width: 800px
}

.util-field-cowork {
    width: 100%;
    height: 10%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cowork-title {
    height: 10%;
    width: 20%;
    min-height: 50px;
    color: rgb(104, 109, 107);
    font-size: 32px;
    line-height: 150%;
    margin-left: 15px;
}

.bottom-cowork-container {

    width: 100%;
    height: fit-content;
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}